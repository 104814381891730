// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
// console.log('Vite ⚡️ Rails')

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

// console.log('Visit the guide for more information: ', 'https://vite-ruby.netlify.app/guide/rails')

// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'
import "@rails/ujs";
import "~/like";
import "~/suggest_usernames";
import "~/suggestion_button";
import "~/sidebar";
import PullToRefresh from "pulltorefreshjs";

document.addEventListener("DOMContentLoaded", function () {
  // @ts-expect-error Property 'standalone' does not exist on type 'Navigator'.ts(2339)
  if (window.navigator.standalone) {
    PullToRefresh.init();
  }
});
